// A minimal polyfill to copy text in clipboard for older browsers that don't have access to the navigator clipboard property.
// Source : https://gist.github.com/lgarron/d1dee380f4ed9d825ca7
//
// Note that:
// - `navigator.clipboard.writeText()` works directly in all modern browsers as of 2020.
// - In Edge, this may call `resolve()` even if copying failed.
// - In Safari, this may fail if there is nothing selected on the page.
//
// License for this Gist: public domain / Unlicense
function clipboardWriteTextPolyfill(str) {
  return new Promise(function (resolve, reject) {
    function listener(e) {
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    const result = document.execCommand("copy");
    document.removeEventListener("copy", listener);

    result ? resolve() : reject(new Error(`Failed to copy "${str}"`));
  });
}

// Add the navigator.clipboard.writeText Polyfill
if (!navigator.clipboard) {
  navigator.clipboard = {};
  navigator.clipboard.writeText = clipboardWriteTextPolyfill;
}
